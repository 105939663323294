export const PLATFORM="Vietnam"
export const URL="vietnam-visa.govis.ai"
export const FLAG_CODE="fi fi-vn"
export const OVERRIDE_LOGO=false
//export const MODE="TEST"
export const MODE="PRODUCTION"
export const WHATSAPP="NO"
export const TITLE="Vietnam"
export const LANG="en"
export const SHOW_PRICE=false
export const WHICH_STRIPE="random"
export const FILTER_COUNTRIES=""
