import React, { lazy, Suspense, useEffect, useState } from "react";
import Box from '@mui/material/Box';
import SpeedIcon from '@mui/icons-material/Speed';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { Card, Grid, CssBaseline, Container, Toolbar, Typography,Button,TextField  } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CookieConsent, { Cookies } from "react-cookie-consent";
import Autocomplete from '@mui/material/Autocomplete';
import CardHeader from '@mui/material/CardHeader';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PublicIcon from '@mui/icons-material/Public';
import CardMedia from '@mui/material/CardMedia';
import Alert from '@mui/material/Alert';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Fab from '@mui/material/Fab';
import {useFloating} from '@floating-ui/react';
import AddIcon from '@mui/icons-material/Add';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CardContent from '@mui/material/CardContent';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ApprovalIcon from '@mui/icons-material/Approval';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import ClipLoader from "react-spinners/ClipLoader";
import * as Globals from "./config/GLOBALS.js";
import CardActions from '@mui/material/CardActions';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import jwt_decode from "jwt-decode";
import "../styles.css";
import ResponsiveAppBar from "./Header.js";
import Footer from "./Footer.js";
import ErrorBoundaries from "./error-boundaries.jsx";
import {submit} from "./api/Functions.js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import actions from "./actions.js";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import * as Constants from "./config/config.js";
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import i18n from "./i18n.ts";
import { useTranslation } from "react-i18next";



import NationalitiesJSON from "./mocks/nationalities.json";
import NationalitiesNonUsJSON from "./mocks/nationalities-non-us.json";
import NationalitiesEU from "./mocks/nationalities-eu.json";
import NationalitiesBrazil from "./mocks/nationalities-brazil.json";
import NationalitiesESTA from "./mocks/nationalities-esta.json";
import NationalitiesILJSON from "./mocks/nationalities-il.json";
import SupportedCountriesJSON from "./mocks/supported_countries.json";

var nationalities = ""
var nationalities_non_us=""
var nationalities_esta=""
var nationalities_eu=""
var nationalities_brazil=""
var supported_countries=[...SupportedCountriesJSON];
if (Constants.LANG=="he")
   nationalities = [...NationalitiesILJSON];
else {
     nationalities = [...NationalitiesJSON];
     nationalities_non_us = [...NationalitiesNonUsJSON]
     nationalities_esta = [...NationalitiesESTA]
     nationalities_eu = [...NationalitiesEU]
     nationalities_brazil = [...NationalitiesBrazil]
}

if (Constants.LANG=="he")
   nationalities = [...NationalitiesILJSON];
else {
     nationalities = [...NationalitiesJSON];
     nationalities_non_us = [...NationalitiesNonUsJSON]
     nationalities_esta = [...NationalitiesESTA]
     nationalities_eu = [...NationalitiesEU]
     nationalities_brazil = [...NationalitiesBrazil]
}


const Dashboard = lazy(() =>  import("./Dashboard.js"));
const Confirmation = lazy(() => import("./confirmation.jsx"));
const Terms = lazy(() => import("./Terms.jsx"));
const Refund = lazy(() => import("./Refund.jsx"));
const RefundHe = lazy(() => import("./Refund-he.jsx"));
const Disclaimer = lazy(() => import("./Disclaimer.jsx"));
const DisclaimerHe = lazy(() => import("./Disclaimer-he.jsx"));
const Privacy = lazy(() => import("./Privacy.jsx"));
const TermsHe = lazy(() => import("./Terms-he.jsx"));
const PrivacyHe = lazy(() => import("./Privacy-he.jsx"));
const Webhook = lazy(() => import("./webhook.jsx"));
const FlightSearch = lazy(() => import("./search/flight-search.jsx"));
const FlightBooking = lazy(() => import("./booking/flight-booking.jsx"));

const Home = () => {
  const [user, setUser] = useState({});
  const [mainPage, setMainPage] = useState(false);
  const [terms, setTerms] = useState(false);
  const [serviceType, setServiceType] = useState("");
  const [inputServiceType, setInputServiceType] = useState("");
  const [nationalityFrom, setNationalityFrom] = useState("");
  const [nationalityWhere, setNationalityWhere] = useState("");
  const [continueGuided, setContinueGuided] = useState(false);
  const [continueNotGuided, setContinueNotGuided] = useState(false);
  const [inputNationalityFrom, setInputNationalityFrom] = useState("");
  const [inputNationalityWhere, setInputNationalityWhere] = useState("");
  const [confirmPage, setConfirmPage] = useState(false);
  const serviceTypeInfo = useSelector((state) => state.flightSearch.serviceTypeInfo);
  const priceInfo = useSelector((state) => state.flightSearch.priceInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const userInfo = useSelector((state) => state.userInfo);
  const applyNowButton = useSelector((state) => state.flightSearch.applyNowButton);
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const params = new URLSearchParams(location.search)





  function handleSignOut(event) {
    setUser({});
    document.getElementById("signInDiv").hidden = false;
  }

    const handleClose = () => {
      setContinueNotGuided(false);


    };


    const handleServiceType = (newVal) => {
      setServiceType(newVal);
      dispatch({
                                 type: actions.SET_SERVICE_TYPE,
                                 data: newVal,

                               });
    //  handleData();
      //alert();


    };
  const delay = async (ms) => {
        return new Promise((resolve) =>
            setTimeout(resolve, ms));
    };

    const handleClick = async () => {
      setIsLoading(true);
        //await delay(1000);
         setIsLoading(false)
         setContinueGuided(true)

         guided.scrollIntoView({ behavior: "smooth" })


    };

    const handleNationalityFrom = (newVal) => {
      setNationalityFrom(newVal);

      //service_type.scrollIntoView({ behavior: "smooth" })
    };

    const handleNationalityWhere = (newVal) => {
      setNationalityWhere(newVal);

      //service_type.scrollIntoView({ behavior: "smooth" })
    };
  useEffect(() => {
    /* global google */


    if (params.get("utm_medium"))
        setMainPage(1)
  //  alert(window.location)
    /*if (window.location.pathname=="/terms" || window.location.pathname=="/privacy")
          setMainPage(true);*/
    if (!Globals.GUIDED.includes(Constants.PLATFORM))
      setContinueGuided(true)
      //alert(window.location)
      //alert(Globals.SERVER_URL)

    if ( window.location.href.indexOf(`https://${Globals.SERVER_URL}/`)!=-1 || window.location.href.indexOf(`https://www.${Globals.SERVER_URL}/`)!=-1)
    {
        setMainPage(true);
    }


    if (window.location.pathname=="/confirmation/")
       setConfirmPage(true);



      /*dispatch({
          type: actions.SET_APPLY_NOW_BUTTON,
          data: true,

        });*/
    /*google.accounts.id.initialize({
      client_id:
        "387074555543-bsr5194lli1qr2mlpuvrdu2si7islvm5.apps.googleusercontent.com",
      callback: handleCallbackResponse
    });

    google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      theme: "outline",
      size: "large"
    });*/

    //google.accounts.id.prompt();
    //
    //console.log(Object.entries(user).length);
    //console.log("DUDU");
  }, []);

  return (

    <div className="root" >


      <CssBaseline />

        <BrowserRouter>

        <ResponsiveAppBar />
        {true && <Box component="span"  >

          {Globals.SERVER=="GOVIS.AI" && <img
          width="80px"
          align="left"
          className="logo-govis"
          display="inline-block"
          src={`https://${Globals.SERVER_URL}/images/GOVIS-WHITE.png`}
          alt={`Govis.ai`}
          loading="lazy"

          onClick={() =>{window.location.assign("https://"+window.location.hostname)}}
          />}



          </Box>}

        <Toolbar />


        {false && <FloatingWhatsApp
        chatMessage={!mainPage? t('welcome') + Constants.PLATFORM + t('online_form'): t('welcome_form')}
        phoneNumber="19173100929"
        accountName={'Emma'}
        statusMessage="24/7 Customer Support"

        />}




        <Container>


        <Box sx={{ pt:15,pb:15,
            backgroundImage: mainPage||1?"url('https://"+Globals.SERVER_URL+"/images/travel_background1.jpg')":"url('https://"+Globals.SERVER_URL+"/images/Travel_Services_Banner.webp')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",

          }}>
        {mainPage && <Box  sx={{ mx:5, mb:1,

            }}>
            <Typography
              variant="h4"
              sx={{
                mt:3,
                fontWeight: 700,
                letterSpacing: '.2rem',
                color: 'black',
                textDecoration: 'none',
              }}
            >

           <Typography
                variant="h4"
                sx={{
                  mt:1,

                  fontWeight: 500,
                  letterSpacing: '.1rem',
                  color: 'black',
                  textDecoration: 'none',
                }}
              >{'The Simplest Way to Apply Online' } </Typography>
              <Typography
                   variant="subtitle1"
                   sx={{
                     mt:1,
                     pt:3,
                     fontWeight: 350,
                     letterSpacing: '.2rem',
                     color: 'black',
                     textDecoration: 'none',
                   }}
                 >{t('let_us_help_you') } </Typography>
              <SpeedIcon />

              <b id="Home"/>
            <br/>
            </Typography>
        </Box>}
        {

          mainPage && <Grid container  >


          <Grid item xs={12} md={6}  sx={{mt:3,backgroundColor:"white"}} >
          <Autocomplete

            required


            id="nationality"
            value={nationalityFrom}
            sx={{

                 mx: 2,
                 mb:2,

                   // border: "1px solid blue",
                   "& .MuiOutlinedInput-root": {
                      border: "solid lightblue",
                    //   borderRadius: "0",
                    //   padding: "0"
                   }
                   //"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  //     border: "1px solid #eee"
                  // }
                 }}
            inputValue={inputNationalityFrom}
            onChange={(event, newValue) => {

              if (newValue==null)
              {

                    handleNationalityFrom("");



              }
              else
              {
                  handleNationalityFrom(newValue);
              }

              //handleNationalityFrom(newValue);


            }}
            onInputChange={(event, newInputValue) => {

                setError("")


              setInputNationalityFrom(newInputValue);
              setTerms(false);
            }}

            onBlur = {(event: React.ChangeEvent<HTMLInputElement>) => {
                setTerms(false);
              //handleData();

            }}
            getOptionLabel={option =>  option === '' ? '': `${option.en_short_name}`}

            options={Constants.PLATFORM=="Morocco"? nationalities_non_us: Constants.PLATFORM=="Brazil"?  nationalities_brazil:(Constants.PLATFORM=="Us" || Constants.PLATFORM=="Canada"? nationalities_esta:Constants.PLATFORM=="Payfine"?nationalities_eu: nationalities)}

            renderOption={(props, option, index) => {
               const key = `listItem-${index}-${option.num_code}`;
               return (
                 (Constants.FILTER_COUNTRIES!="")?
                 ((Constants.FILTER_COUNTRIES.indexOf(option.en_short_name)!=-1 || Constants.FILTER_COUNTRIES.indexOf(option.en_short_name.split(" ")[0]+" "+(option.en_short_name.split(" ")[1]))!=-1 )?
                 <li {...props} key={key}>
                 {<Typography
                   variant="caption"
                 //  noWrap
                   align={Constants.LANG=="he"?"left":""}
                   component="a"
                   //href=""
                   sx={{
                     mr: 2,
                     flexGrow: 1,
                     fontWeight: 500,
                     color: 'black',
                     textDecoration: 'none',
                   }}
                 >
                  { option.en_short_name}
                 </Typography>}
                 </li>:""):<li {...props} key={key}>
                 {<Typography
                   variant="caption"
                 //  noWrap
                   align={Constants.LANG=="he"?"left":""}
                   component="a"
                   //href=""
                   sx={{
                     mr: 2,
                     flexGrow: 1,
                     fontWeight: 500,
                     color: 'black',
                     textDecoration: 'none',
                   }}
                 >
                   <span className={"fi fi-"+option.alpha_2_code.toLowerCase()}></span>&nbsp;
                  { option.en_short_name}
                 </Typography>}
                 </li>
               );
             }}
          //  style={{ width: 150 }}
            renderInput={(params) => (
              <TextField {...params} label={<Typography
                variant="caption"
                component="a"
                sx={{
                  mr: 2,
                  flexGrow: 1,
                  fontWeight: 500,
                  letterSpacing: '.1rem',
                  color: 'black',
                  textDecoration: 'none',
                }}
              >


                {Constants.PLATFORM=="Payfine" ? t('country'):t('where_are_you_from')}
              </Typography>} variant="outlined" />
            )}
            defaultOption={{ num_code: "900", en_short_name: "ישראל" }}

            noOptionsText=<div dir={Constants.LANG=="he"?"rtl":""}>{Constants.LANG=="he"?"יש לבחור אזרחות קודם...": Constants.PLATFORM=="Payfine" ? "Choose Country First:": "Choose an Option..."}</div>

          />

          </Grid>
          <Grid item xs={12} md={6} sx={{mt:3, backgroundColor:"white"}} >
          <Autocomplete

            required


            id="nationality"
            value={nationalityWhere}
            sx={{

              mx: 2,
              mb:2,

                   // border: "1px solid blue",
                   "& .MuiOutlinedInput-root": {
                      border: "solid lightblue",
                    //   borderRadius: "0",
                    //   padding: "0"
                   }
                   //"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  //     border: "1px solid #eee"
                  // }
                 }}
            inputValue={inputNationalityWhere}
            onChange={(event, newValue) => {
              if (newValue==null)
              {
                  handleNationalityWhere("");

              }
              else
              {
                  handleNationalityWhere(newValue);
              }

              //


              setTerms(false);
            }}
            onInputChange={(event, newInputValue) => {

              setError("")


              setInputNationalityWhere(newInputValue);

            }}

            onBlur = {(event: React.ChangeEvent<HTMLInputElement>) => {

              //handleData();

            }}
            getOptionLabel={option =>  option === '' ? '': `${option.en_short_name}`}

            options={Constants.PLATFORM=="Morocco"? nationalities_non_us: Constants.PLATFORM=="Brazil"?  nationalities_brazil:(Constants.PLATFORM=="Us" || Constants.PLATFORM=="Canada"? nationalities_esta:Constants.PLATFORM=="Payfine"?nationalities_eu: nationalities)}

            renderOption={(props, option, index) => {
               const key = `listItem-${index}-${option.num_code}`;
               return (
                 (Constants.FILTER_COUNTRIES!="")?
                 ((Constants.FILTER_COUNTRIES.indexOf(option.en_short_name)!=-1 || Constants.FILTER_COUNTRIES.indexOf(option.en_short_name.split(" ")[0]+" "+(option.en_short_name.split(" ")[1]))!=-1 )?
                 <li {...props} key={key}>
                 {<Typography
                   variant="caption"
                 //  noWrap
                   align={Constants.LANG=="he"?"left":""}
                   component="a"
                   //href=""
                   sx={{
                     mr: 2,
                     flexGrow: 1,
                     fontWeight: 500,
                     color: 'black',
                     textDecoration: 'none',
                   }}
                 >
                  { option.en_short_name}
                 </Typography>}
                 </li>:""):<li {...props} key={key}>
                 {<Typography
                   variant="caption"
                 //  noWrap
                   align={Constants.LANG=="he"?"left":""}
                   component="a"
                   //href=""
                   sx={{
                     mr: 2,
                     flexGrow: 1,
                     fontWeight: 500,
                     color: 'black',
                     textDecoration: 'none',
                   }}
                 >
                   <span className={"fi fi-"+option.alpha_2_code.toLowerCase()}></span>&nbsp;
                  { option.en_short_name}
                 </Typography>}
                 </li>
               );
             }}
          //  style={{ width: 150 }}
            renderInput={(params) => (
              <TextField {...params} label={<Typography
                variant="caption"
                component="a"
                sx={{
                  mr: 2,
                  flexGrow: 1,
                  fontWeight: 500,
                  letterSpacing: '.1rem',
                  color: 'black',
                  textDecoration: 'none',
                }}
              >


                {Constants.PLATFORM=="Payfine" ? t('country'):t('where_are_you_going')}
              </Typography>} variant="outlined" />
            )}
            defaultOption={{ num_code: "900", en_short_name: "ישראל" }}

            noOptionsText=<div dir={Constants.LANG=="he"?"rtl":""}>{Constants.LANG=="he"?"יש לבחור אזרחות קודם...": Constants.PLATFORM=="Payfine" ? "Choose Country First:": "First, choose where are you from"}</div>

          />

          </Grid>
          <Grid item xs={12} md={12}   >
          {<Button sx={{mt:3,mb:5,fontSize:"20px",fontColor:"red", color:"white", backgroundColor:"#24A0ED"}}  variant="contained"

           onClick={(event: React.ChangeEvent<HTMLInputElement>) => {

                setError("")


               if (nationalityFrom==""||nationalityWhere=="")
                {


                    if (nationalityFrom==""&&nationalityWhere=="")
                      setError("Please select where are you from and where are you going to?")
                    if (nationalityFrom==""&&nationalityWhere!="")
                        setError("Please select where are you coming from?")
                        if (nationalityFrom!=""&&nationalityWhere=="")
                            setError("Please select where are you going to?")


                    console.log("Missing Info")
                }
                else
                  {

                        setError("")

                        if (nationalityFrom!=""&&nationalityWhere!="")
                        if (nationalityFrom.en_short_name==nationalityWhere.en_short_name)
                        {
                            setError("Your origin and destination cannot be the same!")
                        }
                        else{
                          var find=false
                          for (var country of supported_countries)
                          {
                            //console.log(country.nationality)
                            //  console.log(nationalityWhere.en_short_name)
                            if (country.nationality.includes(nationalityWhere.en_short_name))
                            {
                              find=true
                              console.log( country.nationality);
                              window.location.href = `https://`+country.prefix+`.${Globals.SERVER_URL}`
                            }
                          }

                          if (!find)
                              setError("We’re sorry, but we can’t assist with a Travel Documents for this destination at this time.")
                        }


                  }
                  //console.log()


           }}

           > {t('get_started')} {Constants.LANG=="he"? <ArrowBackIcon/>:<ArrowForwardIcon/>}</Button>}

          </Grid>

          <Grid item xs={12}  >
          <Box component="span" sx={{ mb:4, color:"#fbf4d6!important", border: '1px sold grey' }}>
          <Typography
            variant="subtitle1"

            component="a"

            sx={{




              fontWeight: 550,

              color: 'black',
              textDecoration: 'none',
            }}
          >
          &nbsp;
          <br/>
          {error}
          </Typography>
        </Box>
          </Grid>



          </Grid>


        }
      </Box>

       {false && mainPage && <Grid container >
       <Grid  >
       <Card sx={{mx:4}} className="maincard" onClick={() =>window.location.href = `https://vietnam.${Globals.SERVER_URL}`} >
         <CardHeader
             title={`Vietnam`}
             subheader="Apply Online"
           />
           <CardContent>
                <Typography sx={{fontSize:150 }}>
                <span className="fi fi-vn"></span><br/>
              </Typography>
           </CardContent>
        </Card>
       <Card sx={{mx:4}} className="maincard" onClick={() =>window.location.href = `https://canada.${Globals.SERVER_URL}`} >
          <CardHeader
              title={"Canada"}
              subheader="Apply Online"
            />
            <CardContent>
                <Typography sx={{fontSize:150 }}>
                 <span className="fi fi-ca"></span><br/>
               </Typography>
            </CardContent>
         </Card>
         <Card sx={{mx:4}} className="maincard" onClick={() =>window.location.href = `https://ethiopia.${Globals.SERVER_URL}`} >
           <CardHeader
               title={"Ethiopia"}
               subheader="Apply Online"
             />
             <CardContent>
                  <Typography sx={{fontSize:150 }}>
                  <span className="fi fi-et"></span><br/>
                </Typography>
             </CardContent>
          </Card>

       <Card sx={{mx:4}} className="maincard" onClick={() =>window.location.href = `https://dubai.${Globals.SERVER_URL}`} >
            <CardHeader
                title={"UAE"}
                subheader="Apply Online"
              />
              <CardContent>
                  <Typography sx={{fontSize:150 }}>
                   <span className="fi fi-ae"></span><br/>
                 </Typography>
              </CardContent>
           </Card>
       <Card sx={{mx:4}} className="maincard" onClick={() =>window.location.href = `https://india.${Globals.SERVER_URL}`} >
             <CardHeader
                 title={"India"}
                 subheader="Apply Online"
               />
               <CardContent>
                  <Typography sx={{fontSize:150 }}>
                    <span className="fi fi-in"></span><br/>

                  </Typography>
               </CardContent>
            </Card>

          <Card  sx={{mx:4}} className="maincard" onClick={() =>window.location.href = `https://morocco.${Globals.SERVER_URL}`} >
             <CardHeader

                 title={"Morocco"}
                 subheader="Apply Online"
               />
               <CardContent>
                  <Typography sx={{fontSize:150 }}>
                    <span className="fi fi-ma"></span><br/>

                  </Typography>
               </CardContent>
            </Card>

            <Card  sx={{mx:4}} className="maincard" onClick={() =>window.location.href = `https://us.${Globals.SERVER_URL}`} >
               <CardHeader

                   title={"United States"}
                   subheader="Apply Online"
                 />
                 <CardContent>
                    <Typography sx={{fontSize:150 }}>
                      <span className="fi fi-us"></span><br/>

                    </Typography>
                 </CardContent>
              </Card>

              <Card  sx={{mx:4}} className="maincard" onClick={() =>window.location.href = `https://srilanka.${Globals.SERVER_URL}`} >
                 <CardHeader

                     title={"Sri Lanka"}
                     subheader="Apply Online"
                   />
                   <CardContent>
                      <Typography sx={{fontSize:150 }}>
                        <span className="fi fi-lk"></span><br/>

                      </Typography>
                   </CardContent>
                </Card>

                <Card  sx={{mx:4}} className="maincard" onClick={() =>window.location.href = `https://cambodia.${Globals.SERVER_URL}`} >
                   <CardHeader

                       title={"Cambodia"}
                       subheader="Apply Online"
                     />
                     <CardContent>
                        <Typography sx={{fontSize:150 }}>
                          <span className="fi fi-kh"></span><br/>

                        </Typography>
                     </CardContent>
                  </Card>

                  <Card  sx={{mx:4}} className="maincard" onClick={() =>window.location.href = `https://bahrain.${Globals.SERVER_URL}`} >
                     <CardHeader

                         title={"Bahrain"}
                         subheader="Apply Online"
                       />
                       <CardContent>
                          <Typography sx={{fontSize:150 }}>
                            <span className="fi fi-bh"></span><br/>

                          </Typography>
                       </CardContent>
                    </Card>

                    <Card  sx={{mx:4}} className="maincard" onClick={() =>window.location.href = `https://newzealand.${Globals.SERVER_URL}`} >
                       <CardHeader

                           title={"New Zealand"}
                           subheader="Apply Online"
                         />
                         <CardContent>
                            <Typography sx={{fontSize:150 }}>
                              <span className="fi fi-nz"></span><br/>

                            </Typography>
                         </CardContent>
                      </Card>

                      <Card  sx={{mx:4}} className="maincard" onClick={() =>window.location.href = `https://uk.${Globals.SERVER_URL}`} >
                         <CardHeader

                             title={"United Kingdom"}
                             subheader="Apply Online"
                           />
                           <CardContent>
                              <Typography sx={{fontSize:150 }}>
                                <span className="fi fi-gb"></span><br/>

                              </Typography>
                           </CardContent>
                        </Card>

                        <Card  sx={{mx:4}} className="maincard" onClick={() =>window.location.href = `https://israel.${Globals.SERVER_URL}`} >
                           <CardHeader

                               title={"Israel"}
                               subheader="Apply Online"
                             />
                             <CardContent>
                                <Typography sx={{fontSize:150 }}>
                                  <span className="fi fi-il"></span><br/>

                                </Typography>
                             </CardContent>
                          </Card>


           </Grid>
          </Grid>}

        { !mainPage && <Box   sx={{ mx:5, mb:1 }}>
            <Typography
              variant="h4"
              sx={{

                mt:3,
                fontWeight: 500,
                letterSpacing: '.1rem',
                color: 'black',
                textDecoration: 'none',
                textTransform: 'uppercase'
              }}
            >


            {!Constants.OVERRIDE_LOGO && <span className={Constants.FLAG_CODE}></span>}

            {Constants.OVERRIDE_LOGO &&  <img
              width="80px"

              align="center"

              //display="inline-block"
              src={`https://${Globals.SERVER_URL}/${Constants.PLATFORM.toLowerCase()}/logo.jpg`}
              alt={`Govis.ai`}
            loading="lazy"
              />}




            <br/>

            {/* Constants.PLATFORM=="India" ?' Start Your Indian Journey: Get Started with Our Free Prequalification Form! Let\'s Begin!' : (Constants.PLATFORM +' Entry Online Form')*/}

            {Constants.LANG=="he" && (Constants.TITLE!="" ? t('the_simplest_way'):Constants.PLATFORM) }
            {Constants.LANG=="en" && !Globals.GUIDED.includes(Constants.PLATFORM) && (Constants.TITLE!="" ?   t('the_simplest_way')  :Constants.PLATFORM) }



            {Constants.LANG=="en" && ( Globals.GUIDED.includes("Us")? t('us_guide'):"" )}
            {Constants.LANG=="en" && ( Globals.GUIDED.includes("Canada") ? t('canada_guide'):"")}


            {Constants.LANG=="en"  && !Constants.PLATFORM=='Us'&& !Constants.PLATFORM=='Canada' && ( Constants.PLATFORM=='Dubai'? 'UAE':Constants.PLATFORM )}<br/></Typography>


            { (Constants.PLATFORM=="Dubai" || Constants.PLATFORM=="Ethiopia" || Constants.PLATFORM=="Us-il" || Constants.PLATFORM=="Morocco" || Constants.PLATFORM=="Morocco-il" || Constants.PLATFORM=="India"  )? (<div><Typography
                variant="h5"

                sx={{
                  mt:1,
                  fontWeight: 300,
                  letterSpacing: '.1rem',
                  color: 'black',
                  fontSize: '1.3rem',
                  textDecoration: 'none',
                     textTransform: 'uppercase'
                }}
              >{t('form_online_expedited') } </Typography> {/*<img
               width="40px"
               center
               src={`https://${Globals.SERVER_URL}/images/siren.png`}
               alt={`Expedited`}
             loading="lazy"
               />*/}{/*<Typography
                  variant="h6"

                  sx={{
                    mt:1,
                    fontWeight: 800,
                    letterSpacing: '.1rem',
                    color: 'black',
                      fontSize: '0.7rem',
                    textDecoration: 'none',
                       textTransform: 'uppercase'
                  }}
                >{'Designed for Rapid Action' } </Typography>*/} </div>):
                Constants.PLATFORM=="Payfine"? t('pay_traffic_fines'):
                Constants.PLATFORM=="IsraelA"? t('online_eta_il'):
                Constants.PLATFORM=="Us"?

                <Typography
                variant="h5"

                sx={{

                  mt:1,
                  fontWeight: 300,
                  letterSpacing: '.1rem',
                  color: 'black',
                    fontSize: '1.3rem',
                  textDecoration: 'none',
                     textTransform: 'uppercase'
                }}
              >{  t('online_esta')  } </Typography>








                :

                Constants.PLATFORM=="Canada"?

                <Typography
                variant="h5"

                sx={{

                  mt:1,
                  fontWeight: 400,
                  letterSpacing: '.1rem',
                  color: 'black',
                    fontSize: '1.3rem',
                  textDecoration: 'none',
                     textTransform: 'uppercase'
                }}
              >{  t('online_eta')  } </Typography>



              :





                 (<div>

                <Typography
                variant="caption"

                sx={{

                  mt:1,
                  fontWeight: 200,
                  letterSpacing: '.0.5rem',
                  color: 'black',
                    fontSize: '1.3rem',
                  textDecoration: 'none',
                     textTransform: 'uppercase'
                }}
              >{t('entry_online_expedited') } </Typography>


              {/*<img
               width="40px"
               center
               src={`https://${Globals.SERVER_URL}/images/siren.png`}
               alt={`Money-back guarantee`}
             loading="lazy"
               />*/} {/*<Typography
                  variant="h6"

                  sx={{
                    mt:1,
                    fontWeight: 800,
                    letterSpacing: '.1rem',
                    color: 'black',
                    fontSize: '0.7rem',
                    textDecoration: 'none',
                       textTransform: 'uppercase'
                  }}
                >{'Designed for Rapid Action' } </Typography>*/}</div>  )}
              { false&& <Typography
                variant="caption"

                sx={{

                  mt:1,
                  fontWeight: 100,
                  letterSpacing: '.1rem',
                  color: 'black',

                  textDecoration: 'none',
                     textTransform: 'uppercase'
                }}
              >  {t('twenty_four_seven')} </Typography>}

              <b id="Home"/>
              <b id="בית"/>



        </Box>}

        {!mainPage && <Typography
        variant="h5"

        sx={{

          mt:1,
          fontWeight: 500,
          letterSpacing: '.1rem',
          color: 'black',
            fontSize: '1.5rem',
          textDecoration: 'none',
             textTransform: 'uppercase'
        }}
      >
      {t('expedited_help')} {Constants.TITLE} </Typography>}
        {false && <Box component="span" >

          {Globals.SERVER=="GOVIS.AI" && <img
          width="80px"

          align="center"

          //display="inline-block"
          src={`https://${Globals.SERVER_URL}/images/GOVIS.png`}
          alt={`Govis.ai`}
        loading="lazy"
          />}



          </Box>}
        <Grid item xs={12} sm={12} sx={{mb:3}}>

        {!mainPage  && !confirmPage && false && <Box component="span" >
              <AccessTimeIcon />
            <Typography
              variant="subtitle1"


              sx={{

                fontWeight: 200,
                color: 'black',
                textDecoration: 'none',
              }}
                  >
              &nbsp;
              {t('one_minute')}
              </Typography>

          </Box>}


      </Grid>
      {/*<Alert severity="warning">{`Tail based alerts are enabled for premium subscribed customers only! this time we let you try it for free`}</Alert>*/}

      <Grid id="guided" item xs={12} sm={12} sx={{mr:5,ml:5,mb:3}}>
      { (Globals.GUIDED.includes(Constants.PLATFORM) || Constants.PLATFORM=='Payfine') && !mainPage  && !confirmPage && <Box   component="span" sx={{ pl:1,pr:1,pb:1, pt:1,color:"#fbf4d6!important", border: '1px sold grey' }}>
          <Typography
            variant="caption"


            sx={{

              fontWeight: 300,
              fontSize: 12,
              color: 'black',
              textDecoration: 'none',
            }}
                >
            &nbsp;
              {/*<Alert severity="info">  </Alert>*/}
              {Constants.PLATFORM=='Us'? t('disclaimer_top'):(Constants.PLATFORM=='Canada'? t('disclaimer_top_canada'):(Constants.PLATFORM=='Payfine'? t('disclaimer_top_payfine'):"")) }

            </Typography>
        </Box>}
    </Grid>
        <Grid item xs={12} sm={12} sx={{mb:3}}>
        {!mainPage  && !confirmPage && <Box id="entry" component="span" sx={{ pl:1,pr:1,pb:1, pt:1,backgroundColor: "#fbf4d6!important",color:"#fbf4d6!important", border: '1px sold grey' }}>
            <Typography
              variant="caption"


              sx={{

                fontWeight: 300,

                color: 'black',
                textDecoration: 'none',
              }}
                  >
              &nbsp;
              {t('important_note')}
              </Typography>
          </Box>}
      </Grid>

      <Grid item xs={12} sm={12} sx={{mb:3}}>
      {  continueGuided && !mainPage && !confirmPage && <Box component="span" sx={{ pl:1,pr:1,pb:1, pt:1,color:"#fbf4d6!important", border: '1px sold grey' }}>
          <Typography
            variant="caption"
              display="inline"

            sx={{

              fontWeight: 200,

              color: 'black',
              textDecoration: 'none',
            }}
                >
            &nbsp;
            {Constants.PLATFORM=="Payfine" ? t('reveal_pricing1_payfine'):t('reveal_pricing1')}
            </Typography>
            <Typography
              variant="caption"
              display="inline"


              sx={{

                fontWeight: 200,
                color: 'black',
                textDecoration: 'none',
              }}
                  >
              &nbsp;
              {/*<br/>*/}
              {Constants.PLATFORM=="Payfine" ? t('reveal_pricing2_payfine'):t('reveal_pricing2')}
              </Typography>
        </Box>}
    </Grid>

    <Grid item xs={12} sm={12} sx={{mr:5,ml:5,mb:3}}>
    {   Globals.GUIDED.includes(Constants.PLATFORM) && continueGuided && !mainPage && !confirmPage && <Box component="span" sx={{ pl:1,pr:1,pb:1, pt:1,color:"#fbf4d6!important", border: '1px sold grey' }}>
        <Typography
          variant="caption"
            display="inline"

          sx={{

            fontWeight: 250,
            fontSize: 12,
            color: 'black',
            textDecoration: 'none',
          }}
              >
          &nbsp;

            {/*<Alert severity="warning">  {t('privacy_matter')}</Alert>*/}
             {t('privacy_matter')}
          </Typography>

      </Box>}
  </Grid>

        <Grid
            container
            styles={{ marginTop: 100 }}
          >
          <Grid item xs={12} sm={12}>
              <ErrorBoundaries>
                <Suspense fallback={<div>{t('loading')}</div>}>
                <ClipLoader
                    color={"#000000"}
                    loading={isLoading}
                  //  cssOverride={override}
                    size={50}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />

                    { !continueGuided &&   <br/>}


                { Globals.GUIDED.includes(Constants.PLATFORM) && !mainPage && !continueGuided && <Button  sx={{mr:5,mt:2, fontSize:"20px",fontColor:"red", color:"white", backgroundColor:"#24A0ED"}}  variant="contained"

                onClick={(event: React.ChangeEvent<HTMLInputElement>) => {

                  handleClick()

                }}

                > {t('Continue Guided')} {Constants.LANG=="he"? <ArrowBackIcon/>:<ArrowForwardIcon/>}</Button>}

                <Dialog
                //   fullScreen={fullScreen}
                   open={continueNotGuided}
                   onClose={handleClose}
                   aria-labelledby="responsive-dialog-title"
                 >
                   <DialogTitle id="responsive-dialog-title">
                     {"Important Message!"}
                   </DialogTitle>
                   <DialogContent>
                     <DialogContentText>

                        {Constants.LANG=="en" && ( Constants.PLATFORM=='Us'? t('dialog_guided_us'):"" )}
                        {Constants.LANG=="en" && ( Constants.PLATFORM=='Canada'? t('dialog_guided_canada'):"" )}

                     </DialogContentText>
                   </DialogContent>
                   <DialogActions>
                     {/*<Button autoFocus onClick={handleClose}>
                       Disagree
                     </Button>*/}
                     {Globals.GUIDED.includes(Constants.PLATFORM) && !continueGuided && <Button sx={{mr:5,mt:2,mb:2, fontSize:"20px",fontColor:"red", color:"white", backgroundColor:"#24A0ED"}}  variant="contained"

                     onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                       setContinueNotGuided(false)
                       handleClick()

                     }}

                     > {t('Continue Guided')} {Constants.LANG=="he"? <ArrowBackIcon/>:<ArrowForwardIcon/>}</Button>}
                   </DialogActions>
                 </Dialog>
                {Globals.GUIDED.includes(Constants.PLATFORM) && !mainPage &&  !continueGuided && <Button sx={{mt:2,fontSize:"20px",fontColor:"red", color:"white", backgroundColor:"black"}}  variant="contained"

                onClick={(event: React.ChangeEvent<HTMLInputElement>) => {


                  setContinueNotGuided(true)
                  //handleClick()

                }}

                > {t('Not Guided')} {Constants.LANG=="he"? <ArrowBackIcon/>:<ArrowForwardIcon/>}</Button>}
                  { !continueGuided &&   <br/>}

                { applyNowButton.result && !priceInfo.result && !mainPage && <button class="floating-button"   onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                    guided.scrollIntoView({ behavior: "smooth" })

                    handleClick()

                  }}>{t('apply_now_get_expert')}</button>}
                  <Routes>


                    {continueGuided && !mainPage&& <Route
                      exact={true}
                      path={`/`}
                      element={<FlightSearch/>}
                    /> }
                    { !mainPage&&<Route
                      exact={true}
                      path={`/confirmation`}
                      element={<Confirmation/>}
                    />}

                    { <Route
                      exact={true}
                      path={`/terms`}
                      element={<Terms/>}
                    />}
                    { <Route
                      exact={true}
                      path={`/terms-he`}
                      element={<TermsHe/>}
                    />}

                    { <Route
                      exact={true}
                      path={`/refund-policy`}
                      element={<Refund/>}
                    />}
                    { <Route
                      exact={true}
                      path={`/refund-policy-he`}
                      element={<RefundHe/>}
                    />}

                    { <Route
                      exact={true}
                      path={`/disclaimer`}
                      element={<Disclaimer/>}
                    />}
                    { <Route
                      exact={true}
                      path={`/disclaimer-he`}
                      element={<DisclaimerHe/>}
                    />}

                    { <Route
                      exact={true}
                      path={`/privacy`}
                      element={<Privacy/>}
                    />}
                    { <Route
                      exact={true}
                      path={`/privacy-he`}
                      element={<PrivacyHe/>}
                    />}
                    { 0 && <Route
                      exact={true}
                      path={`/webhook`}
                      element={<Webhook/>}
                    />}
                  </Routes>
                </Suspense>
              </ErrorBoundaries>
              <Footer mainPage={mainPage}/>
            </Grid>
          </Grid>
        </Container>
        </BrowserRouter>
      <br />

    </div>

  );
};

export default Home;
